<template>
  <section class="h-screen">

    <video id="background-video" autoplay loop muted poster="https://storage.googleapis.com/ta-publicsite/images/relatus_video_bg_image.jpg">
      <source src="https://storage.googleapis.com/ta-publicsite/videos/relatus_iStock-149742008.mp4" type="video/mp4">
    </video>

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_profile_white_2.svg"
          style="max-height: 60px;"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase text-white">Pre-register</span>
        <img
          src="@/assets/images/menu_white.svg"
          style="display: inline-block;margin-left: 20px;max-height: 100px;"
        >
      </div>
    </div>

    <div class="flex h-screen"  style="height:80vh;">
      <div class="m-auto text-center">
        <img
          src="@/assets/images/relatus_white_logo.png"
          class="m-auto mb-5"
          style="height: 80px;"
        >

        <h5 class="text text-white" style="font-size: 22px;">Achieve great things together</h5>

        <div class="pb-10" style="margin-top: 80px;">

          <router-link
            :to="{name: 'registration.v2.welcome'}"
            class="btn btn-primary align-self-center me-auto mt-4 block"
          >
            Create Account
          </router-link>

          <router-link
            :to="{name: 'registration.v2.welcome'}"
            class="btn btn-primary align-self-center me-auto mt-4 block social-signin"
          >
            <img
              src="@/assets/images/social/ic_apple_original.svg"
              class="inline-block mr-1"
              style="padding-bottom: 7px;"
            > Create Account
          </router-link>

          <a
            href="https://relativity-php-api-pm3flabd3a-uc.a.run.app/google-oauth"
            class="btn btn-primary align-self-center me-auto mt-4 block social-signin"
          >
            <img
              src="@/assets/images/social/ic_google_original.svg"
              class="inline-block mr-1"
              style="padding-bottom: 6px;"
            > Create Account
          </a>

          <a
            href="https://relativity-php-api-pm3flabd3a-uc.a.run.app/facebook"
            class="btn btn-primary align-self-center me-auto mt-4 block social-signin"
          >
            <img
              src="@/assets/images/social/ic_facebook_original.svg"
              class="inline-block mr-1"
              style="padding-bottom: 6px;"
            > Create Account
          </a>

          <h5 class="text text-white mt-5 mb-5 block m-auto" style="font-size: 14px;max-width: 350px;">By signing up for Relatus, you agree to our Terms of Service. Learn how we process your data in our Privacy Policy and Cookies Policy.</h5>

          <h5 class="text text-white mb-5 block m-auto" style="font-size: 14px;max-width: 350px;">- or -</h5>

          <router-link
            :to="{name: 'registration.v2.signin'}"
            class="align-self-center me-auto block text-white font-bold"
          >
            Sign In
          </router-link>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Registration',
  mounted() {
    localStorage.removeItem('firstname');
    localStorage.removeItem('lastname');
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 54px;
  line-height: 70px;
  letter-spacing: 1px;
  font-weight: 300;
}
h3 > strong {
  font-weight: bold;
}
.social-signin {
  background-color: rgba(71, 85, 105, 0.2)!important;
  border-color: transparent;
}
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  filter: brightness(0.7);
}
</style>
